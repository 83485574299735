@mixin keyframes($animation-name) {
	@-webkit-keyframes #{$animation-name} {
		@content;
	}
	@-moz-keyframes #{$animation-name} {
		@content;
	}
	@-ms-keyframes #{$animation-name} {
		@content;
	}
	@-o-keyframes #{$animation-name} {
		@content;
	}
	@keyframes #{$animation-name} {
		@content;
	}
}

@mixin animation($str) {
	-webkit-animation: #{$str};
	-moz-animation: #{$str};
	-ms-animation: #{$str};
	-o-animation: #{$str};
	animation: #{$str};
}

@mixin transform($args...) {
	-webkit-transform: $args;
	-moz-transform: $args;
	-o-transform: $args;
	transform: $args;
}

@mixin transition($args...) {
	-webkit-transition: $args;
	-moz-transition: $args;
	-ms-transition: $args;
	-o-transition: $args;
	transition: $args;
}

@mixin opacity($opacity) {
	opacity: $opacity;
	$opacity-ie: $opacity * 100;
	filter: alpha(opacity=$opacity-ie); //IE8
}

@mixin placeholder {
	&::-webkit-input-placeholder{
		@content;
	}
	&:-moz-placeholder{
		@content;
	}
	&::-moz-placeholder{
		@content;
	}
	&:-ms-placeholder{
		@content;
	}
}