@import "variables";
@import "media-queries";
@import "mixins";

.stepped-form {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;

	-webkit-flex-flow: row nowrap;
	justify-content: start;
	align-items: center;

	overflow: hidden;

	@include transition(all 280ms ease-in);

	.background-box {
		position: fixed;
		top: 50%;
		left: 50%;
		z-index: 100;

		width: 12px;
		height: 12px;

		-webkit-border-radius: 50% 50%;
		-moz-border-radius: 50% 50%;
		border-radius: 50% 50%;

		background-color: #ffffff;

		@include transition(transform 780ms ease-in-out);
		@include transform(scale3d(0, 0, 1));
	}

	.close-btn {
		position: fixed;
		top: 0;
		right: 0;
		z-index: 101;

		font-size: 60px;
		color: #202020;
		opacity: 0.25;

		@include transition(transform 380ms cubic-bezier(0.95, 0, 0.25, 1.4), opacity 380ms cubic-bezier(0.95, 0, 0.25, 1.4));
		@include transform(translate3d(-45px, -100px, 1px));
	}

	.step {
		position: relative;
		flex-grow: 0;
		flex-shrink: 0;
		flex-basis: 100%;

		/* To override old rule in the old css... */
		height: auto;
		border: none;

		width: 100%;
		max-width: 720px;

		label {
			display: block;
			padding-top: 0;
			font-size: 1.186rem;
			margin-bottom: 15px;
			color: #8D8D8D;
			font-weight: bold;
		}

		.step-input {
			position: relative;
			border-bottom: 3px solid #939393;

			input {
				width: 100%;
				border: none;
				outline: 0;
				background: transparent;

				padding: 7.5px 20px 7.5px 0;
				font-weight: bold;
				font-size: 1.567rem;
				color: #000000;

				@include placeholder {
					color: #d8d8d8;
				}
			}

			&.text {
				input {

				}
			}

			.stepped-form-btn-next {
				position: absolute;
				top: -4px;
				right: 10px;

				outline: 0;
				font-size: 44px;
				padding: 0;
				border: 0;
				background: transparent;
				color: #939393;

				display: -webkit-box;
				display: -moz-box;
				display: -ms-flexbox;
				display: -webkit-flex;
				display: flex;

				-webkit-flex-flow: row nowrap;
				justify-content: start;
				align-items: center;

				@include transition(color 190ms ease-in-out, font-size 190ms ease-in-out);

				.fa-circle-o-notch {
					font-size: 28px;
					margin-top: 12px;
					color: #C6AA76;
				}

				&:hover, &:focus {
					color: #C6AA76;
				}
			}
		}

		.progress-hint {
			margin-top: 14px;
			text-align: right;
			color: #B4B4B4;
		}

		.step-input-description {
			/**
			 * Custom Checkbox
			 */
			.custom-checkbox {
				display: block;
				position: relative;
				padding-left: 35px;
				margin-bottom: 12px;
				cursor: pointer;
				font-size: 22px;
				-webkit-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}

			/* Hide the browser's default checkbox */
			.custom-checkbox input {
				position: absolute;
				opacity: 0;
				cursor: pointer;
			}

			/* Create a custom checkbox */
			.custom-checkbox .checkmark {
				position: absolute;
				top: 0;
				left: 0;
				height: 25px;
				width: 25px;
				background-color: #e0e0e0;
				border-radius: 2px;

				@include transition(background-color 240ms ease-in);
			}

			/* On mouse-over, add a grey background color */
			.custom-checkbox:hover input ~ .checkmark {
				background-color: #ccc;
			}

			/* When the checkbox is checked, add a blue background */
			.custom-checkbox input:checked ~ .checkmark {
				background-color: #c6aa76;
			}

			/* Create the checkmark/indicator (hidden when not checked) */
			.custom-checkbox .checkmark:after {
				content: "";
				position: absolute;
				display: none;
			}

			/* Show the checkmark when checked */
			.custom-checkbox input:checked ~ .checkmark:after {
				display: block;
			}

			/* Style the checkmark/indicator */
			.custom-checkbox .checkmark:after {
				left: 10px;
				top: 6px;
				width: 5px;
				height: 10px;
				border: solid white;
				border-width: 0 3px 3px 0;
				-webkit-transform: rotate(45deg);
				-ms-transform: rotate(45deg);
				transform: rotate(45deg);
			}
		}
	}

	&.active {
		position: fixed;
		top: 50%;

		/* Correct the vertical position based on height */
		margin-top: -45px;

		width: 100%;
		max-width: 720px;

		z-index: 99;

		.background-box {
			@include transform(scale3d(2000, 2000, 1));
		}

		.close-btn {
			@include transform(translate3d(-45px, 45px, 1px));
			@include transition(transform 380ms cubic-bezier(0.95, 0, 0.25, 1.4));

			&:hover, &:focus {
				opacity: 0.55;
			}
		}

		.step {
			z-index: 100;
		}
	}
}