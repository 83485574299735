
// Body
$body-bg: #f0f0f0;

// Brands
$brand-primary: #c6aa76;
$brand-primary-second: #b2b2b2;
$brand-backface: #ffffff;
$brand-backface-2: #000000;
$brand-backface-3: #f0f0f0;

// Fonts
$primary-font: 16px 'ubunturegular';