@font-face {
	font-family: 'ubunturegular';

	src: url('/sierraminera/static/web/fonts/ubuntu-r-webfont.eot');
	src: url('/sierraminera/static/web/fonts/ubuntu-r-webfont.eot?#iefix') format('embedded-opentype'),
	url('/sierraminera/static/web/fonts/ubuntu-r-webfont.woff2') format('woff2'),
	url('/sierraminera/static/web/fonts/ubuntu-r-webfont.woff') format('woff'),
	url('/sierraminera/static/web/fonts/ubuntu-r-webfont.ttf') format('truetype'),
	url('/sierraminera/static/web/fonts/ubuntu-r-webfont.svg#ubunturegular') format('svg');
	font-weight: normal;
	font-style: normal;

}

@font-face {
	font-family: 'ubunturegular';

	src: url('/sierraminera/static/web/fonts/ubuntu-b-webfont.eot');
	src: url('/sierraminera/static/web/fonts/ubuntu-b-webfont.eot?#iefix') format('embedded-opentype'),
	url('/sierraminera/static/web/fonts/ubuntu-b-webfont.woff2') format('woff2'),
	url('/sierraminera/static/web/fonts/ubuntu-b-webfont.woff') format('woff'),
	url('/sierraminera/static/web/fonts/ubuntu-b-webfont.ttf') format('truetype'),
	url('/sierraminera/static/web/fonts/ubuntu-b-webfont.svg#ubunturegular') format('svg');
	font-weight: bold;
	font-style: normal;

}