@import "variables";
@import "media-queries";

.general-section {
  /**
   * Alter alignment of containers from left to right when the container is even or odd
   */
  .alter-left-right {
	float: right;

	margin-right: 0;
	margin-left: 30px;
  }

  &:nth-child(odd) .alter-left-right {
	float: left;

	margin-right: 30px;
	margin-left: 0;
  }

  /**
   * Alter alignment of containers from left to right when the container is even or odd with flexbox order
   */
  .alter-left-right-flex {
	float: left;
	order: 0;

	margin-right: 30px;
	margin-left: 0;
  }

  &:nth-child(odd) .alter-left-right-flex {
	float: right;
	order: 1;

	margin-right: 0;
	margin-left: 30px;
  }

  .section-image {
	width: 100%;
	height: 100%;
	object-fit: cover;
  }
}

.promotion-section {

  @include for-tablet-landscape-up {
	text-align: center;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-flow: row wrap;
	justify-content: space-between;
	align-items: center;
  }

  /**
   * Alter alignment of containers from left to right when the container is even or odd
   */
  .alter-left-right {
	float: left;

	margin-right: 30px;
	margin-left: 0;
  }

  &:nth-child(odd) .alter-left-right {
	float: right;

	margin-right: 0;
	margin-left: 30px;
  }

  /**
   * Alter alignment of containers from left to right when the container is even or odd with flexbox order
   */
  .alter-left-right-flex {
	float: left;
	order: 0;

	margin-right: 30px;
	margin-left: 0;
  }

  &:nth-child(odd) .alter-left-right-flex {
	float: right;
	order: 1;

	margin-right: 0;
	margin-left: 30px;
  }

  /**
   * Promotion main image
   */
  .promotion-image-container {
	/**
	 * Top left badge
	 */
	&:after {
	  position: absolute;
	  top: -11px;
	  left: -11px;
	  z-index: 11;
	}

	/**
	 * Top left badge
	 */
	&:before {
	  content: "";
	  position: absolute;
	  height: 80px;
	  width: 80px;
	  top: 17px;
	  right: 17px; /* + 30 de padding*/
	  display: block;
	  z-index: 11;

	  @include for-phone-only {
		height: 68px;
		width: 68px;
		top: 8px;
		right: 8px;
	  }
	}

	&.on-sale:after {
	  content: url("/sierraminera/static/web/css/icons/promo_on_sale.svg");
	}
	&.in-construction:after, &.in-construction.on-sale:after {
	  content: url("/sierraminera/static/web/css/icons/promo_in_construction.svg");
	}
	&.done:after, &.done.on-sale:after {
	  content: url("/sierraminera/static/web/css/icons/promo_done.svg");
	}
	&.on-sale.done:before, &.latest-sell:before {
	  background: url("/sierraminera/static/web/css/icons/latest_on_sale.svg");
	  background-size: cover;
	}
	&.on-sale.done:before, &.last-property:before {
	  background: url("/sierraminera/static/web/css/icons/last_on_sale.svg");
	  background-size: cover;
	}
	&.all-sold:before {
	  background: url("/sierraminera/static/web/css/icons/100_sold.svg");
	  background-size: cover;
	}
	&.has-discount {
	  .ribbon-offer {
		display: block;
	  }
	}

	&.latest-sell.has-discount, &.last-property.has-discount, &.all-sold.has-discount {
	  .ribbon-offer {
		top: 115px;

		@media (max-width: 599px) {
		  top: 88px;
		}
	  }
	}

	.ribbon-offer {
	  display: none;
	  position: absolute;
	  top: 17px;
	  right: 0;
	  z-index: 2;
	  width: 182px;
	  height: 42px;

	  background-image: url("/images/SierraMinera_Rebajas_Icono_TX_AF.svg");
	  background-size: 198px 42px;
	}

	.adapta-icon {
	  display: none;
	}
	&.plan-adapta .adapta-icon {
	  display: block;
	}

	.adapta-icon {
	  position: absolute;
	  height: 80px;
	  width: 80px;
	  bottom: 17px;
	  right: 17px; /* + 30 de padding*/
	  z-index: 11;

	  background: url("/sierraminera/css/icons/adapta.svg");
	  background-size: cover;

	  @include for-phone-only {
		height: 68px;
		width: 68px;
		bottom: 8px;
		right: 8px;
	  }
	}
  }

  .details-container {
	text-align: center;
	width: 100%;

	@include for-tablet-landscape-up {
	  width: 43%;
	}

	.promotion-infography {
	  display: inline-block;
	  width: 300px;
	  height: 130px;
	  margin-bottom: 34px;

	  @include for-phone-only {
		width: 90%;
	  }

	  img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	  }
	}

	.promotion-details {
	  color: $brand-primary;
	  margin-bottom: 30px;
	  line-height: 28px;

	  span.promotion-name {
		display: block;
		color: #000;
		text-transform: uppercase;
		font-weight: 700;
	  }
	  span.promotion-specs {
		display: block;
	  }
	}
  }
}

.dark-section {
  width: 100%;

  .background-image {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;

	overflow: hidden;

	img {
	  width: 100%;
	  height: 100%;
	  object-fit: cover;
	}
  }

  .inner-container {
	position: relative;
	width: 100%;
	min-height: 200px;
	padding: 40px;

	color: $brand-backface;
	background-color: rgba(0, 0, 0, 0.7);

	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;

	@include for-tablet-landscape-up {
	  flex-direction: row;
	  justify-content: space-between;
	  padding: 65px;

	  &.reverse {
		flex-direction: row-reverse;
	  }
	}

	.title {
	  text-align: center;
	  font-size: 1.2rem;
	  font-weight: bold;
	  margin-bottom: 30px;

	  @include for-tablet-landscape-up {
		font-size: 1.4rem;
		margin-bottom: 0;
		margin-right: 30px;

		max-width: 480px;
	  }

	  @include for-desktop-up {
		max-width: 720px;
	  }
	}
  }
}

.bottom-map-section {
  margin-bottom: 25px;

  /**
   * Alter alignment of containers from left to right when the container is even or odd with flexbox order
   */
  .alter-left-right-flex {
	float: left;
	order: 0;

	margin-right: 30px;
	margin-left: 0;
  }

  &:nth-child(odd) .alter-left-right-flex {
	float: right;
	order: 1;

	margin-right: 0;
	margin-left: 30px;
  }

  @include for-tablet-landscape-up {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-flow: row wrap;
	justify-content: space-between;
	align-items: center;
  }

  /**
   * Align text to right when the container is right aligned
   */
  &:nth-child(odd) .info-container {
	@include for-tablet-landscape-up {
	  text-align: right;
	  margin-left: auto;
	}
  }

  .info-container {
	text-align: center;
	width: 100%;
	line-height: 28px;

	@include for-tablet-landscape-up {
	  width: 43%;
	  text-align: left;
	}

	h3, .title {
	  font-size: 26px;
	  line-height: 40px;
	  margin-bottom: 30px;
	}

	.address, .extra-info, .global-footnote {
	  margin-bottom: 40px;
	}

	.extra-info {
	  p {
		margin: 0;
	  }
	}

	.global-footnote {
	  color: #000000;
	}
  }

  .gmap {
	width: 100%;
	height: 100%;
	background-color: $brand-primary;
  }
}

.gmap-directions-button {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -webkit-flex-flow: row wrap;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 19;

  background-color: #4285F4;
  color: #fff;

  padding: 4px 8px;

  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px -1px;
  border-radius: 2px;

  @include for-phone-only {
	padding: 0;
	background-color: transparent;
	border-radius: 50%;
  }

  @include for-tablet-portrait-up {
	padding: 8px 12px;
  }

  &:active, &:focus, &:active:hover, &:active:focus, &:hover {
	color: #fff;
	background-color: #4285F4;

	@include for-phone-only {
	  background-color: transparent;
	}
  }

  .directions-icon-wrapper {
	background-color: #fff;
	border-radius: 50%;
	padding: 10px;
	margin-right: 10px;

	@include for-phone-only {
	  margin-right: 0;
	  background-color: #4285f4;
	}

	.directions-icon-svg {
	  display: block;
	  width: 24px;
	  height: 24px;

	  @include for-phone-only {
		display: none;
	  }
	}

	.directions-icon-svg-inverted {
	  display: none;
	  width: 24px;
	  height: 24px;

	  @include for-phone-only {
		display: block;
	  }
	}
  }

  .text {
	@include for-phone-only {
	  display: none;
	}
  }
}

.centered-form-container {
  max-width: 720px;
  margin: 20px auto 90px auto;
}

.reviews-slider {
  .swiper-container {
	.slider-nav {
	  .swiper-pagination {
		.indicator {
		  margin: 0 15px 15px 15px;
		}
	  }
	}
  }
}
