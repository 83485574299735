@import "variables";

.btn-smr {
	/**
	 * White button
	 */
	&.whitey {
		&:before,
		&:after {
			border-color: $brand-backface;
		}

		.btn-content {
			color: $brand-backface;
		}
	}

	/**
	 * Brand button
	 */
	&.brand {
		&:before,
		&:after {
			border-color: $brand-primary;
		}

		.btn-content {
			color: $brand-primary;
		}
	}

	/**
	 * Black button borders
	 */
	&.blacky {
		&:before,
		&:after {
			border-color: #000000;
		}

		.btn-content {
			color: #000000;
		}
	}

	/**
	 * White fill when hover
	 */
	&.foreground-white {
		&:hover:before,
		&:hover:after {
			border-color: $brand-backface;
		}

		&:hover .btn-content {
			color: $brand-primary;
			background-color: $brand-backface;
		}
	}

	/**
	 * Brand fill when hover
	 */
	&.foreground-brand {
		&:hover:before,
		&:hover:after {
			border-color: $brand-primary;
		}

		&:hover .btn-content {
			color: $brand-backface;
			background-color: $brand-primary;
		}
	}

	/**
	 * White fill with black color when hover
	 */
	&.foreground-white-black {
		&:hover:before,
		&:hover:after {
			border-color: #000000;
		}

		&:hover .btn-content {
			color: #ffffff;
			background-color: #000000;
		}
	}
}