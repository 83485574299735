@import "variables";
@import "media-queries";

/**
 * Aspect Ratio Containers
 */

/* 1:1 */
.aspect-ratio-box {
	width: 100%;
	height: 0 !important;
	padding-top: 100%;
	position: relative;

	/* Inner container */
	.aspect-ratio-box-inside {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	/* 4:3 */
	&.r4-3 {
		padding-top: 75%;
	}

	/* 5:4 */
	&.r5-4 {
		padding-top: 80%;
	}

	/* 16:9 */
	&.r16-9 {
		padding-top: 56.25%;
	}

	@include for-phone-only {
		/* 4:3 */
		&.phone-r4-3 {
			padding-top: 75%;
		}

		/* 5:4 */
		&.phone-r5-4 {
			padding-top: 80%;
		}

		/* 16:9 */
		&.phone-r16-9 {
			padding-top: 56.25%;
		}
	}
}