// Importing the fonts
@import "fonts";
// Variables
@import "variables";
// Sections
@import "sections";
// Media-Query mixins
@import "media-queries";
// Animations
@import "animations";
// Mixins
@import "mixins";
// Aspect ratios
@import "aspect-ratios";
// Custom styled buttons
@import "buttons";
// Stepped form
@import "stepped-form";
@import "~angular-bootstrap-colorpicker/css/colorpicker.css";
// Bootstrap
//@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');

body {
  color: $brand-backface-2;
  background-color: $brand-backface-3;
  font: $primary-font;
}

.slider-yt-frame {
  width: 100%;
  height: 100%;
}

.input-group {
  .input-group-btn {
	.btn {
	  padding: 5px 12px;
	}
  }
}

.swiper-container {
  .swiper-slide {
	.background-image {
	  img {
		position: absolute;

		&.on-top {
		  object-fit: contain;
		  padding: 50px;
		}
	  }
	}
	.text-wrapper {
	  .text-container {

		@include for-phone-only {
		  padding: 10px;
		}
	  }
	}
	.btn-stack {
	  .btn-smr {
		margin-right: 20px;
		margin-bottom: 20px;

		&:last-of-type {
		  margin-bottom: 0;
		}
	  }
	}
  }
}

.footer-nav {
  a {
	text-decoration: underline;
  }
}

.floating-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 39;
  transition: transform 340ms ease-in;

  background-color: #8d8d8d;
  border-top: 1px solid darken(#8d8d8d, 3%);

  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  -ms-flex-direction: row;
  flex-direction: row;

  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: center;
  align-content: center;

  -moz-justify-content: space-around;
  -webkit-justify-content: space-around;
  justify-content: space-around;

  -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.75);

  @media (min-width: 1090px) {
	display: none;
  }

  .stacked {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;

	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;

	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;

	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-line-pack: center;
	align-content: center;

	-moz-justify-content: center;
	-webkit-justify-content: center;
	justify-content: center;

	flex-basis: 100%;
  }

  .item {
	padding: 10px 16px 6px 16px;
	color: #ffffff;
	transition: background-color 290ms cubic-bezier(0.95, 0, 0.25, 1.4);

	&:hover, &:active {
	  background-color: transparent;
	}

	&.touched {
	  background-color: #202020;
	}

	.bounding-box {
	  height: 22px;
	  width: 22px;
	  margin-bottom: 5px;

	  -webkit-box-sizing: border-box;
	  box-sizing: border-box;

	  display: -webkit-box;
	  display: -ms-flexbox;
	  display: flex;

	  -webkit-box-orient: vertical;
	  -webkit-box-direction: normal;
	  -ms-flex-direction: column;
	  flex-direction: column;

	  -webkit-box-align: center;
	  -ms-flex-align: center;
	  align-items: center;
	  -ms-flex-line-pack: center;
	  align-content: center;

	  -moz-justify-content: center;
	  -webkit-justify-content: center;
	  justify-content: center;
	}

	&.btn-contact {
	  .bounding-box {
		width: 24px;

		img {
		  margin-top: -2px;
		  margin-left: -1px;
		}
	  }
	}

	&.btn-call {
	  .bounding-box img {
		width: auto;
		height: 21px;
	  }
	}

	img {
	  width: 100%;
	  height: 100%;
	  object-fit: cover;
	}

	@media (max-width: 599px) {
	  padding: 10px 8px 6px 8px;
	  font-size: 0.8rem;
	}

	@media (max-width: 320px) {
	  padding: 10px 2px 6px 2px;
	  font-size: 0.75rem;
	}
  }
}

.bottom-map-section {
  .extra-info[contenteditable] {
	border: 4px dashed rgba(20, 20, 20, 0.1);
	min-height: 40px;
	padding: 0 12px;
	border-radius: 3px;
	background-color: rgba(20, 20, 20, 0.01);
  }

  .extra-info {
	a, a:link, a:visited {
	  color: #c6aa76;
	}
  }
}

/**
 * Custom Checkbox
 */
.custom-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.custom-checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #ffffff;
  border-radius: 2px;
}

/* On mouse-over, add a grey background color */
.custom-checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.custom-checkbox input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.custom-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.custom-checkbox .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.show-gt-sm {
  display: none;

  @media (min-width: 768px) {
	display: block;
  }
}

.gt-sm {
  display: none;

  @media (max-width: 767px) {
	display: block;
  }
}

.full-text-support {
  h2 {
	font-weight: bold;
	font-size: 30px;
  }
  h3 {
	font-weight: bold;
	font-size: 24px;
  }
}

/* The ribbons */
.corner-ribbon {
  width: 480px;
  background: #e43;
  position: absolute;
  text-align: center;
  line-height: 64px;
  letter-spacing: 1px;
  font-weight: bold;
  color: #f0f0f0;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  z-index: 99;
  text-transform: uppercase;

  .ribbon {
	display: none;
  }

  /**
   * Positions
   */
  &.top-left {
	top: 105px;
	left: -96px;
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
  }
  &.top-right {
	top: 105px;
	right: -96px;
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
  }
  &.bottom-left {
	bottom: 105px;
	left: -96px;
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
  }
  &.bottom-right {
	bottom: 105px;
	right: -96px;
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
  }

  @media (max-width: 959px) {
	/* Increasing the text size just a bit */
	font-size: 1.30rem;
	line-height: 50px;
	width: auto;
	padding: 0 10px;

	.ribbon {
	  width: 50px;
	  height: 50px;
	  position: absolute;
	  top: 0;
	  display: block;
	  z-index: -1;
	}

	/**
	  * Positions
	  */
	&.top-left {
	  top: 10px;
	  left: 0px;
	  transform: rotate(0deg);
	  -webkit-transform: rotate(0deg);

	  .ribbon {
		right: -25px;
		transform: rotate3d(0,1,0,180deg);
	  }
	}

	&.top-right {
	  top: 10px;
	  right: 0px;
	  transform: rotate(0deg);
	  -webkit-transform: rotate(0deg);

	  .ribbon {
		left: -25px;
		transform: rotate3d(0,1,0,0deg);
	  }
	}
	&.bottom-left {
	  bottom: 10px;
	  left: 0px;
	  transform: rotate(0deg);
	  -webkit-transform: rotate(0deg);

	  .ribbon {
		right: -25px;
		transform: rotate3d(0,1,0,180deg);
	  }
	}
	&.bottom-right {
	  bottom: 10px;
	  right: 0px;
	  transform: rotate(0deg);
	  -webkit-transform: rotate(0deg);

	  .ribbon {
		left: -25px;
		transform: rotate3d(0,1,0,0deg);
	  }
	}
  }
  @media (min-width: 960px) and (max-width: 1279px) {
	font-size: 1.30rem;

	/**
	  * Positions
	  */
	&.top-left {
	  top: 112px;
	  left: -96px;
	  transform: rotate(-45deg);
	  -webkit-transform: rotate(-45deg);
	}

	&.top-right {
	  top: 112px;
	  right: -96px;
	  transform: rotate(45deg);
	  -webkit-transform: rotate(45deg);
	}
	&.bottom-left {
	  bottom: 112px;
	  left: -96px;
	  transform: rotate(45deg);
	  -webkit-transform: rotate(45deg);
	}
	&.bottom-right {
	  bottom: 112px;
	  right: -96px;
	  transform: rotate(-45deg);
	  -webkit-transform: rotate(-45deg);
	}
  }
  @media (min-width: 1280px) {
	font-size: 1.30rem;
	line-height: 76px;

	/**
	  * Positions
	  */
	&.top-left {
	  top: 105px;
	  left: -96px;
	  transform: rotate(-45deg);
	  -webkit-transform: rotate(-45deg);
	}

	&.top-right {
	  top: 105px;
	  right: -96px;
	  transform: rotate(45deg);
	  -webkit-transform: rotate(45deg);
	}
	&.bottom-left {
	  bottom: 105px;
	  left: -96px;
	  transform: rotate(45deg);
	  -webkit-transform: rotate(45deg);
	}
	&.bottom-right {
	  bottom: 105px;
	  right: -96px;
	  transform: rotate(-45deg);
	  -webkit-transform: rotate(-45deg);
	}
  }

  /**
   * Other properties
   */
  &.sticky {
	position: fixed;
  }

  &.shadow {
	box-shadow: 0 0 3px rgba(0, 0, 0, .3);
  }
}

.whatsapp-floating {
	position: fixed;
	bottom: 80px;
	right: 20px;
	z-index: 98;
	width: 52px;
	height: 52px;
	animation: float 3s ease-in-out infinite;
	border-radius: 50%;
	background-color: #25d366;
	padding: 10px;
	-webkit-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.75);
	-moz-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.75);
	box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.75);

	@media (min-width: 1090px) {
		bottom: 30px;
	}

	.wa-link {
		display: block;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
}

.mdc-text-field {
	padding-top: 16px;
	padding-left: 0;
	/*&.mdc-text-field--label-floating {
		.mdc-floating-label {
			padding-top: 15px ;
		}
	} */
}

.mdc-floating-label--float-above {
	transform: translateY(-130%) scale(0.75) !important;

}

.mdc-text-field--disabled .mdc-floating-label {
	color: rgba(0,0,0,.75);
}

.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
	color: rgb(198, 170, 118);
}

.mdc-text-field {
	overflow: visible !important;
}

.mdc-button {
	min-width: 0 ;
	margin-right: 10px;

	&:last-of-type {
		margin-right: 0;
	}

}



.formula-de-pago-wrapper {

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.flex-row {
		display: flex;
		flex-direction: row;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;

		* {
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			box-sizing: border-box;
		}
	}

	.flex-column {
		display: flex;
		flex-direction: column;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;

		* {
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			box-sizing: border-box;
		}
	}

	.justify-content-flex-start { justify-content: flex-start; }

	.justify-content-flex-end { justify-content: flex-end; }

	.justify-content-center { justify-content: center; }

	.align-items-flex-start { align-items: flex-start; }

	.align-items-flex-end { align-items: flex-end; }

	.align-items-center { align-items: center; }

	.flex-wrap { flex-wrap: wrap; }

	.fill { flex: 1 1 0; }

	.fill-10 { flex: 0 0 10%; }

	.fill-15 { flex: 0 0 15%; }

	.fill-18 { flex: 0 0 18%; }

	.fill-20 { flex: 0 0 20%; }

	.fill-40 { flex: 0 0 40%; }

	.fill-50 { flex: 0 0 50%; }

	.fill-33 { flex: 0 0 32%; }

	.fill-responsive-100 {
		@media (max-width: 680px){
			flex: 1 1 100%;
		}
	}

	.fill-responsive-50 {
		@media (max-width: 680px){
			flex: 0 0 50%;
		}
	}

	.fill-responsive-25 {
		@media (max-width: 680px){
			flex: 0 0 25%;
		}
	}

	.fill-responsive-15 {
		@media (max-width: 680px){
			flex: 0 0 15%;
		}
	}

	.hidden-text-field { visibility: hidden; }

	.responsive-hidden {
		display: none;

		@media (min-width: 680px) {
			display: flex;
		}
	}

	.rotate-45 {
		transform: rotate(-45deg);

	}

	.material-icons {
		color: rgb(198, 170, 118);
	}

	.responsive-no-margin {
		@media (max-width: 679px){
			margin: 0 !important;
		}
	}

	.responsive-padding-right-5 {
		@media (max-width: 679px){
			padding-right: 5px !important;
		}
	}

	.no-margin-right {
		margin-right: 0 !important;
	}

	.margin-bottom-negative-4 {
		margin-bottom: -4px !important;
	}

	h1 {
		font-size: 52px;
		line-height: 64px;
		color: #c6aa76;
		font-weight: 700;
		margin: 50px 0 25px 0;

		@media (max-width: 680px){
			font-size: 25px;
			line-height: 64px;
			font-weight: 700;
			margin: 12px 0;
		}

	}

	.formula-de-pago {
		background: white;
		width: 100%;
		padding: 10px;

		@media (min-width: 680px) {
			max-width: 720px;
			padding: 30px;
		}

		label.title{
			font-size: 15px;
			font-weight: bold;
			color: #000000;
			margin: 8px 0 0 0 !important;
			position: relative;
			top: 15px;
		}

		.mdc-text-field.error {
			.mdc-line-ripple::before {
				border-bottom-color: rgba(255, 0, 0, 0.8) !important;
			}

			.mdc-text-field__input {
				color: rgba(255, 0, 0, 0.8) !important;
			}

		}

		.mdc-text-field.warning {
			.mdc-line-ripple::before {
				border-bottom-color: rgba(255, 77, 0, 0.8) !important;
			}

			.mdc-text-field__input {
				color: rgba(255, 77, 0, 0.8) !important;
			}
		}

		.text-field-margin {
			margin-bottom: 10px;

			.mdc-text-field , .flex-row {
				margin-right: 10px;

				&:last-of-type {
					margin-right: 0;
				}
			}
		}


		.tag-container{
			display: flex;
			flex-direction: row;

			@media (min-width: 680px) {
				margin-left: 8px;
			}


			.tag {
				position: relative;
				overflow: hidden;
				display: flex;
				justify-content: center;
				flex: 1 1 0;
				align-items: center;
				padding: 8px;
				margin: 0 10px 14px 0;
				background-color: transparent;
				color: rgba(99,99,99,.94);
				border: 1px solid rgba(99,99,99,.87);

				@media (max-width: 680px) {
					padding: 12px 8px;
				}

				&.checked {
					color: rgba(0,0,0,.98);
					background-color: rgba(198,170,118,.98);
					border-color: rgba(198,170,118,.87);
				}

				&:last-of-type {
					margin-right: 0;
				}

				input {
					position: absolute;
					top: -30px;
					left: -80px;
				}

			}
		}

		.show-last-button{
			.recibos-row {
				.add-button {
					display: none;
				}

				&:last-of-type {
					.add-button{
						display: block;
					}
				}

			}
		}

		.recibos-row {
			.text-fields-row {
				flex: 0 0 53.12%;

				@media (max-width: 679px) {
					flex: 0 0 84%;
				}
			}

			.button-row {
				@media (max-width: 679px) {
					position: relative;
					left: -30px;
				}
			}
		}

		.mdc-text-field__icon{
			&.mdc-text-field__icon--trailing {
				opacity: 0.6;
				position: relative;
				right: -14px;
				font-size: 20px;
			}
		}
	}

}

@import url(//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);

fieldset, label { margin: 0; padding: 0; }

h1 { font-size: 1.5em; margin: 10px; }

/****** Style Star Rating Widget *****/

.rating-star {
	border: none;
	float: left;
}

.rating-star > input { display: none; }
.rating-star > label:before {
	margin: 5px;
	font-size: 3em;
	font-family: FontAwesome;
	display: inline-block;
	content: "\f005";
}

.rating-star > .half:before {
	content: "\f089";
	position: absolute;
}

.rating-star > label {
	color: #ddd;
	float: right;
}

/***** CSS Magic to Highlight Stars on Hover *****/

.rating-star > input:checked ~ label, /* show gold star when clicked */
.rating-star:not(:checked) > label:hover, /* hover current star */
.rating-star:not(:checked) > label:hover ~ label { color: #C6AA76;  } /* hover previous stars in list */

.rating-star > input:checked + label:hover, /* hover current star when changing rating */
.rating-star > input:checked ~ label:hover,
.rating-star > label:hover ~ input:checked ~ label, /* lighten current selection */
.rating-star > input:checked ~ label:hover ~ label { color: #F4D394;  }



.pull-wraper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .flex-row {
    display: flex;
    flex-direction: row;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    * {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }
  }

  .flex-column {
    display: flex;
    flex-direction: column;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    * {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }
  }

  .justify-content-flex-start { justify-content: flex-start; }

  .justify-content-flex-end { justify-content: flex-end; }

  .justify-content-center { justify-content: center; }

  .align-items-flex-start { align-items: flex-start; }

  .align-items-flex-end { align-items: flex-end; }

  .align-items-center { align-items: center; }

  .flex-wrap { flex-wrap: wrap; }

  .fill { flex: 1 1 0; }

  .fill-10 { flex: 0 0 10%; }

  .fill-15 { flex: 0 0 15%; }

  .fill-18 { flex: 0 0 18%; }

  .fill-20 { flex: 0 0 20%; }

  .fill-40 { flex: 0 0 40%; }

  .fill-50 { flex: 0 0 50%; }

  .fill-33 { flex: 0 0 32%; }

  .fill-70 { flex: 0 0 32%; }

  .fill-responsive-100 {
    @media (max-width: 680px){
      flex: 1 1 100%;
    }
  }

  .fill-responsive-50 {
    @media (max-width: 680px){
      flex: 0 0 50%;
    }
  }

  .fill-responsive-25 {
    @media (max-width: 680px){
      flex: 0 0 25%;
    }
  }

  .fill-responsive-15 {
    @media (max-width: 680px){
      flex: 0 0 15%;
    }
  }

  .width-50 {
    width: 50%;
  }


	.width-80 {
		width: 80%;
	}

	.width-responsive-100 {
    @media (max-width: 680px){
      width: 100%;
    }
  }


  h1 {
    font-size: 36px;
    line-height: normal;
    color: #c6aa76;
    font-weight: 700;
    margin: 20px 0 20px 0;
	justify-content: center;

    @media (max-width: 680px){
		font-size: 20px;
      	line-height: normal;
		font-weight: 700;
		margin: 10px 0 10px 0;
    }

  }

  .question-text {
    justify-content: center;
	  text-align: center;
    padding-top: 0;
    font-size: 19px;
    color: #8d8d8d;
    font-weight: 700;
	  margin: 10px;
    @media (max-width: 680px){
      font-size: 15px;
      line-height: normal;
      font-weight: 700;
		margin-top: 0px;
    }
  }

  .description-text {
    justify-content: center;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    color: #555555;
    margin-bottom: 30px;
    @media (max-width: 680px){
      font-size: 18px;
      line-height: normal;
      font-weight: 300;
      margin-bottom: 50px;
    }
  }


  .align-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .free-text {
    width: 500px;
    margin-top: 30px;
    margin-bottom: 20px;
    border-bottom: #909090;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom-style: solid;
    background: #f0f0f0;

	  @media (max-width: 680px){
		 width: 300px
	  }

	.free-text::placeholder {
		color: #555555;
		font-weight: 700;
	}
  }

  .nav-buttons{
    outline: none;
    border: 0;
    background: none;
  }

  .button-form {
	  background-color: rgb(198, 170, 118);
	  text-transform: capitalize;
	  border: 0;
	  font-size: 14px;
	  font-weight: 500;
	  letter-spacing: 0.14px;
	  padding: 12px;
	  color: rgba(0, 0, 0, 0.87);
	  box-shadow: rgba(0, 0, 0, 0.26) 0 2px 5px 0;

	  &:hover {
		  filter: brightness(0.9);

	  }
  }

  .agile-form {
	  margin-bottom: 10px;
  }

  .nav-icon {
    font-size: 50px;
    line-height: 64px;
    color: #8d8d8d;
    font-weight: 500;
    font-font: serif;
  }

  .small-text {
    font-size: 14px;
    line-height: 64px;
    color: #8d8d8d;
    font-weight: 700;
    padding: 0 10px 0 10px;
  }

  .tag-container {
    padding: 10px;
    input {
      visibility:hidden;
      padding: 10px 10px 10px 10px;
    }
    label {
      cursor: pointer;
      padding: 10px 20px 10px 20px;
		text-align: center;
		margin-bottom: 4px;
    }
    input:checked + label {
      background: #c6aa76;
      color: black;
    }
  }
}

.responsive-margin-coa {
	@media (min-width: 680px){
		margin-left: 250px;
		margin-right: 250px;
	}
}

.responsive-margin-ul {
	margin-left: 30px;
	@media (min-width: 680px){
		margin-left: 40px;
	}
}

.interest-info{
  color: white !important;
  font-size: 2.1rem !important;
  padding: 0rem 14rem !important;
  line-height: 2.5rem !important;
	@media(max-width: 1000px){
		padding: 0rem 6rem !important;
	}
	@media(max-width: 768px){
		padding: 0rem !important;
		font-size: 1.2rem !important;
		line-height: 1.5rem !important;
	}
}

.interest-info-position{
  display:flex !important;
  flex-direction: column !important;
  aling-items: center !important;
}

.mt-1-5{
  margin-top: 1.5rem !important;
 }
